module.exports = [{
      plugin: require('/codebuild/output/src500684183/src/node_modules/gatsby-plugin-canonical-urls/gatsby-browser.js'),
      options: {"plugins":[],"siteUrl":"https://www.casinoindex.se"},
    },{
      plugin: require('/codebuild/output/src500684183/src/node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-P6LSGTS","includeInDevelopment":false,"defaultDataLayer":{"type":"object","value":{"platform":"gatsby"}}},
    },{
      plugin: require('/codebuild/output/src500684183/src/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
